import { createSlice } from '@reduxjs/toolkit';
import { IEventState } from '@administration/interfaces/IEvent';
import { fetchEventAttendees, fetchEventData } from '../actions/event.action';

const initialState: IEventState = {
  isLoading: false,
  eventData: null,
  attendeeStatus: null,
};

export const eventSlice = createSlice({
  name: 'eventSlice',
  initialState,
  reducers: {
    resetEventState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventAttendees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEventAttendees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.attendeeStatus = action.payload;
      })
      .addCase(fetchEventAttendees.rejected, (state) => {
        state.isLoading = false;
        state.attendeeStatus = null;
      })
      .addCase(fetchEventData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEventData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.eventData = action.payload;
      })
      .addCase(fetchEventData.rejected, (state) => {
        state.isLoading = false;
        state.eventData = null;
      });
  },
});

export const { resetEventState } = eventSlice.actions;
export default eventSlice.reducer;
