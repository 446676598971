import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEventAttendees, getEvents } from '@administration/apis/events';
import { getProduct } from '@administration/apis/product';

export const fetchEventAttendees = createAsyncThunk('event/fetchEventAttendees', async () => {
  try {
    const response = await getEventAttendees();
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Failed to fetch event attendees');
  } catch (error) {
    throw new Error('Failed to fetch event attendees');
  }
});

export const fetchEventData = createAsyncThunk(
  'event/fetchEventData',
  async (payload: { countryId: string; productId: string[] }) => {
    try {
      const response = await getEvents(payload);
      if (response.status === 200) {
        return response.data;
      }
      throw new Error('Failed to fetch events');
    } catch (error) {
      throw new Error('Failed to fetch events');
    }
  },
);

export const fetchEventProducts = createAsyncThunk(
  'usecasePanel/fetchEventProducts',
  async (productIds: string[], { rejectWithValue }) => {
    const responses = await Promise.all(productIds.map((productId) => getProduct(productId)));
    const failedResponses = responses.filter((res) => res.status < 200 || res.status >= 300);
    const products = responses.map((res) => res.data);
    if (failedResponses.length > 0) {
      return rejectWithValue('CAN_NOT_FETCH_PRODUCTS');
    }
    return { products };
  },
);
