import { IUsecaseConfigurations, IOption } from '../../../../../interfaces/IUsecases';

export const OPTIONS: IOption[] = [
  { label: 'GLOBAL_NOT_APPLICABLE', name: 'Not applicable' },
  { label: 'GLOBAL_VERY_LOW', name: 'Very Low' },
  { label: 'GLOBAL_LOW', name: 'Low' },
  { label: 'GLOBAL_MEDIUM', name: 'Medium' },
  { label: 'GLOBAL_HIGH', name: 'High' },
  { label: 'GLOBAL_VERY_HIGH', name: 'Very High' },
];

export const USECASE_CONFIGURATIONS: IUsecaseConfigurations = {
  CALL_PACING: {
    children: [
      {
        name: 'GLOBAL_SETTINGS',
        settings: [
          {
            title: 'NBA_CONFIGURATION_MCCP_COVERAGE',
            hasOptions: false,
          },
          {
            title: 'NBA_CONFIGURATION_PRIORITIES',
            hasOptions: false,
          },
          {
            title: 'NBA_CONFIGURATION_ROLE',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_RELATIVE_STRENGTH',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_ADOPTION_LADDER',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_TOP_ACCOUNTS',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_HCP_SPECIALTY',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_HCP_TYPE',
            hasOptions: true,
          },
        ],
      },
    ],
  },
};

export const USECASE_OPTIONS = [{ value: 'CALL_PACING', label: 'GLOBAL_CALL_PACING' }];

export const NAMES_REGEX = /^([\w\s]+)\s*-\s*([\w\s-]+)$/;
export const PRODUCT_REGEX = /user\.product\.id\s*==\s*([a-f0-9\-]+)/;
export const COUNTRY_REGEX = /user\.country\.id\s*==\s*([a-f0-9\-]+)/;
export const USER_TO_EXCLUDE_REGEX = /user\.id\s*!=\s*([a-f0-9\-]+)/g;
export const USER_TO_INCLUDE_REGEX = /user\.id\s*==\s*([a-f0-9\-]+)/g;

export const PRIORITIES = [
  {
    translationKey: 'GLOBAL_ALL',
    value: 'ALL',
  },
  {
    translationKey: 'P1',
    value: 'P1',
  },
  {
    translationKey: 'P2',
    value: 'P2',
  },
  {
    translationKey: 'P3',
    value: 'P3',
  },
  {
    translationKey: 'P4',
    value: 'P4',
  },
  {
    translationKey: 'P5',
    value: 'P5',
  },
];

export const CALL_INVITED = 'callAcceptance';
export const CALL_ATTENDANCE = 'callAttendance';
export const EMAIL_ATTENDANCE = 'EmailAttendance';
export const EVENT_FOLLOWUP = 'eventFollowUp';
export const EVENT_SECOND_FOLLOWUP = 'eventSecondFollowUp';
