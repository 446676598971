import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUsecasesInitialState } from '../../../../../interfaces/IUsecases';
import { reshapeResponse } from '../utils';
import { fetchUsecases } from '../actions/usecase.action';

const initialState: IUsecasesInitialState = {
  usecases: [],
  originalUsecases: [],
  count: 0,
  isLoading: false,
  isError: false,
  pagers: {
    next: null,
    prev: null,
  },
};

const usecasesSlice = createSlice({
  name: 'usecases',
  initialState,
  reducers: {
    deleteUsecases: (state, action: PayloadAction<string[]>) => {
      state.usecases = state.usecases.filter((usecase) => !action.payload.includes(usecase.id));
      state.originalUsecases = state.originalUsecases.filter(
        (usecase) => !action.payload.includes(usecase.id),
      );
      state.count = state.count - action.payload.length;
    },
    toggleActivation: (state, action: PayloadAction<{ usecaseId: string }>) => {
      const { usecaseId } = action.payload;
      const usecase = state.usecases.find((usecase) => usecase.id === usecaseId);
      const originalUsecase = state.originalUsecases.find((usecase) => usecase.id === usecaseId);
      if (usecase) {
        usecase.running = usecase.running === 'enabled' ? 'disabled' : 'enabled';
      }
      if (originalUsecase) {
        originalUsecase.status = originalUsecase.status === 'enabled' ? 'disabled' : 'enabled';
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsecases.fulfilled, (state, action: PayloadAction<any>) => {
        state.usecases = reshapeResponse(action.payload.values);
        state.originalUsecases = action.payload.values;
        state.count = action.payload['$count'];
        state.pagers = {
          next: action.payload.next,
          prev: action.payload.prev,
        };
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchUsecases.rejected, (state, _action: PayloadAction<any>) => {
        state.usecases = [];
        state.originalUsecases = [];
        state.count = 0;
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(fetchUsecases.pending, (state, _action: PayloadAction<any>) => {
        state.usecases = [];
        state.originalUsecases = [];
        state.count = 0;
        state.isLoading = true;
        state.isError = false;
      });
  },
});

export const { deleteUsecases, toggleActivation } = usecasesSlice.actions;
export default usecasesSlice.reducer;
