import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ConfigurationItem,
  IUsecase,
  IUsecaseSliceState,
  TypeMeasurementType,
  TypeSettingsType,
} from '@administration/interfaces/IUsecases';
import { IEventDispatch } from '@administration/interfaces/IEvent';
import {
  CALL_INVITED,
  CALL_ATTENDANCE,
  COUNTRY_REGEX,
  EVENT_FOLLOWUP,
  EVENT_SECOND_FOLLOWUP,
  NAMES_REGEX,
  PRODUCT_REGEX,
} from '../constants';

import { IProductResponse } from 'administration/interfaces/IProduct';
import { ICountryResponse } from 'administration/interfaces/ICountry';
import { fetchUsers } from '../actions/user-manager.action';
import { fetchAudiencesMembers } from '../actions/audience.action';
import { fetchEventProducts } from '../actions/event.action';

const USECASE_CONFIGURATIONS = {
  CALL_PACING: {
    children: [
      {
        name: 'GLOBAL_SETTINGS',
        settings: [
          {
            title: 'NBA_CONFIGURATION_MCCP_COVERAGE',
            active: true,
          },
          {
            title: 'NBA_CONFIGURATION_PRIORITIES',
            active: true,
          },
          {
            title: 'NBA_CONFIGURATION_ROLE',
            active: false,
            selectedOption: '',
          },
          {
            title: 'NBA_CONFIGURATION_RELATIVE_STRENGTH',
            active: false,
            selectedOption: '',
          },
          {
            title: 'NBA_CONFIGURATION_ADOPTION_LADDER',
            active: false,
            selectedOption: '',
          },
          {
            title: 'NBA_CONFIGURATION_TOP_ACCOUNTS',
            active: false,
            selectedOption: '',
          },
          {
            title: 'NBA_CONFIGURATION_HCP_SPECIALTY',
            active: false,
            selectedOption: '',
          },
          {
            title: 'NBA_CONFIGURATION_HCP_TYPE',
            active: false,
            selectedOption: '',
          },
        ],
      },
    ],
  },
};

const initialState: IUsecaseSliceState = {
  usecase: 'CALL_PACING',
  isOpen: false,
  id: null,
  audienceId: null,
  audienceMembers: {
    count: 0,
    isLoading: false,
    error: null,
  },
  status: 'enabled',
  country: null,
  product: null,
  users: {
    include: {
      values: [],
      isLoading: false,
      error: null,
    },
    exclude: {
      values: [],
      isLoading: false,
      error: null,
    },
  },
  updateError: false,
  usecaseConfigurations: USECASE_CONFIGURATIONS,
  marketShare: {
    title: 'NBA_CONFIGURATION_MARKET_SHARE',
    active: false,
    selectedOption: '',
    SettingType: 'GLOBAL',
    activity_thresholds: {
      minimal_activity: {
        value: '',
        type: 'MOT',
      },
      low_alert_level: '',
      high_alert_level: '',
    },
  },
  event: {
    title: 'NBA_CONFIGURATION_MCCP_EVENT',
    active: false,
    types: [],
    statuses: [],
    topics: [],
    products: {
      values: null,
      isLoading: false,
      error: null,
    },
    configurations: {
      [CALL_INVITED]: {
        active: false,
        target: 'attendee',
        days: 0,
        priorities: [],
        attendee_status: [],
      },
      [CALL_ATTENDANCE]: {
        active: false,
        target: 'attendee',
        days: 0,
        priorities: [],
        attendee_status: [],
      },
      [EVENT_FOLLOWUP]: {
        active: false,
        target: 'attendee',
        days: 0,
        priorities: [],
        attendee_status: [],
      },
      [EVENT_SECOND_FOLLOWUP]: {
        active: false,
        target: 'attendee',
        days: 0,
        priorities: [],
        attendee_status: [],
      },
    },
  },
};

const usecasePanel = createSlice({
  name: 'usecasePanel',
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true;
    },
    close: (state) => {
      state.isOpen = false;
    },
    clearUsecase(state) {
      state.usecase = initialState.usecase;
      state.id = initialState.id;
      state.audienceId = initialState.audienceId;
      state.country = initialState.country;
      state.product = initialState.product;
      state.users = initialState.users;
      state.usecaseConfigurations = initialState.usecaseConfigurations;
      state.marketShare = initialState.marketShare;
      state.event = initialState.event;
      state.audienceMembers = initialState.audienceMembers;
      state.updateError = false;
    },
    setUsecase(state, action) {
      state.usecase = action.payload;
    },
    setId(state, action) {
      state.id = action.payload;
    },
    setEventProducts: (state, action) => {
      state.event.products.values = action.payload;
    },
    toggleStatus(state, action) {
      state.status = action.payload.status;
    },
    setAudienceId(state, action) {
      state.audienceId = action.payload;
    },
    setCountry(state, action) {
      state.country = action.payload;
    },
    setProduct(state, action) {
      state.product = action.payload;
    },
    setUsersToInclude(state, action) {
      state.users.include.values = action.payload;
    },
    setUsersToExclude(state, action) {
      state.users.exclude.values = action.payload;
    },
    updateMarketshare: (
      state,
      action: PayloadAction<{
        value: boolean | string;
        flag: 'CHECK' | 'OPTION' | 'ACTIVITY' | 'SETTING_TYPE';
        vFlag?: 'MINIMAL_VALUE' | 'MINIMAL_TYPE' | 'LOW' | 'HIGH';
      }>,
    ) => {
      if (action.payload.flag === 'CHECK') {
        state.marketShare.active = action.payload.value as boolean;
      } else if (action.payload.flag === 'OPTION') {
        state.marketShare.selectedOption = action.payload.value as string;
      } else if (action.payload.flag === 'SETTING_TYPE') {
        state.marketShare.SettingType = action.payload.value as TypeSettingsType;
      } else if (
        action.payload.flag === 'ACTIVITY' &&
        state.marketShare.SettingType === 'SPECIFIC'
      ) {
        if (action.payload.vFlag === 'MINIMAL_VALUE') {
          state.marketShare.activity_thresholds.minimal_activity.value = action.payload
            .value as string;
        }
        if (action.payload.vFlag === 'MINIMAL_TYPE') {
          state.marketShare.activity_thresholds.minimal_activity.type = action.payload
            .value as TypeMeasurementType;
        }
        if (action.payload.vFlag === 'LOW') {
          state.marketShare.activity_thresholds.low_alert_level = action.payload.value as string;
        }
        if (action.payload.vFlag === 'HIGH') {
          state.marketShare.activity_thresholds.high_alert_level = action.payload.value as string;
        }
      }
    },
    updateSetting: (
      state,
      action: PayloadAction<{
        useCase: string;
        name: string;
        flag: 'CHECK' | 'OPTION';
        value: any;
      }>,
    ) => {
      const { useCase, name, flag, value } = action.payload;
      const useCaseConfig = state.usecaseConfigurations[useCase];

      if (useCaseConfig) {
        const setting = useCaseConfig.children
          .flatMap((child: any) => child.settings)
          .find((setting: any) => setting.title === name);

        if (setting) {
          if (flag === 'CHECK') {
            setting.active = value;
          } else if (typeof setting.selectedOption !== 'undefined' && flag === 'OPTION') {
            setting.selectedOption = value;
          }
        }
      }
    },
    setEvent: (state, action: PayloadAction<{ type: IEventDispatch; payload: any }>) => {
      const { type, payload } = action.payload;
      const EventActions = {
        ACTIVATION: () => {
          state.event.active = payload;
        },
        TYPES: () => {
          state.event.types = payload;
        },
        STATUS: () => {
          state.event.statuses = payload;
        },
        TOPICS: () => {
          state.event.topics = payload;
        },
        CONFIGURATION: () => {
          state.event.configurations = payload;
        },
        EMAILS: () => {
          state.event.configurations.approved_emails = payload;
        },
      };

      if (type in EventActions) {
        EventActions[type]();
      }
    },
    sliceUpdateUsecase: (
      state,
      action: PayloadAction<{
        usecaseId: string;
        originalUsecases: IUsecase[];
      }>,
    ) => {
      const { usecaseId, originalUsecases } = action.payload;

      const toEditUsecase = originalUsecases.find((usecase: IUsecase) => usecase.id === usecaseId);
      if (!toEditUsecase) {
        state.updateError = true;
        return;
      }

      const { flow, audiences, id, status } = toEditUsecase;

      if (!flow?.configuration || !audiences?.length) {
        state.updateError = true;
        return;
      }

      const { configuration } = flow;
      const audience = audiences[0];

      state.isOpen = true;
      state.id = id;
      state.status = status as 'enabled' | 'disabled';

      Object.entries(configuration).forEach(([_key, configItem]: [string, ConfigurationItem]) => {
        const { active, can_be_modified, name, value } = configItem;

        // Update setting
        const useCaseConfig = state.usecaseConfigurations['CALL_PACING'];
        if (useCaseConfig) {
          const setting = useCaseConfig.children
            .flatMap((child: any) => child.settings)
            .find((setting: any) => setting.title === name);
          if (setting) {
            setting.active = active;
            if (can_be_modified) {
              setting.selectedOption = value;
            }
          }
        }

        if (name === 'NBA_CONFIGURATION_MARKET_SHARE') {
          // Handle market share update
          state.marketShare.active = active;
          if (can_be_modified && value) {
            state.marketShare.selectedOption = value;
          }

          const { market_share_values } = configItem;
          if (market_share_values) {
            if (
              active &&
              ['SPECIFIC', 'AI_GENERATED', 'GLOBAL'].includes(market_share_values.type)
            ) {
              state.marketShare.SettingType = market_share_values.type as TypeSettingsType;
            }

            if (market_share_values.type === 'SPECIFIC') {
              const { minimal_activity, low_alert_level, high_alert_level } =
                market_share_values.activity_thresholds;
              state.marketShare.activity_thresholds.minimal_activity.value = minimal_activity.value;
              state.marketShare.activity_thresholds.minimal_activity.type = minimal_activity.type;
              state.marketShare.activity_thresholds.low_alert_level = low_alert_level;
              state.marketShare.activity_thresholds.high_alert_level = high_alert_level;
            }
          }
        }

        if (name === 'NBA_CONFIGURATION_MCCP_EVENT') {
          // Update event configuration
          if (flow.configuration?.event) {
            const config = flow.configuration.event;
            if (config && config.configurations) {
              state.event = {
                ...state.event,
                active: config.active,
                types: config.configurations.events.types,
                statuses: config.configurations.events.statuses,
                topics: config.configurations.events.topics,
                configurations: {
                  ...state.event.configurations,
                  [CALL_INVITED]:
                    config.configurations.calls.find(
                      (call: any) => call.idx === 1 && call.timeline === 'before',
                    ) || state.event.configurations[CALL_INVITED],
                  [CALL_ATTENDANCE]:
                    config.configurations.calls.find(
                      (call: any) => call.idx === 0 && call.timeline === 'before',
                    ) || state.event.configurations[CALL_ATTENDANCE],
                  [EVENT_FOLLOWUP]:
                    config.configurations.calls.find(
                      (call: any) => call.idx === 0 && call.timeline === 'after',
                    ) || state.event.configurations[EVENT_FOLLOWUP],
                  [EVENT_SECOND_FOLLOWUP]:
                    config.configurations.calls.find(
                      (call: any) => call.idx === 1 && call.timeline === 'after',
                    ) || state.event.configurations[EVENT_SECOND_FOLLOWUP],
                },
              };
            }
          }
        }
      });

      // Handle audience update
      const { rule, name: audienceName, id: audienceId } = audience;
      state.audienceId = audienceId;

      const productMatch = rule.match(PRODUCT_REGEX);
      const countryMatch = rule.match(COUNTRY_REGEX);
      const nameMatch = audienceName.match(NAMES_REGEX);
      if (productMatch && countryMatch && nameMatch) {
        const [, productId] = productMatch;
        const [, countryId] = countryMatch;
        const [, countryName, productName] = nameMatch;
        state.product = { id: productId, name: productName } as IProductResponse;
        state.country = { id: countryId, name: countryName } as ICountryResponse;
      } else {
        state.updateError = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state, action) => {
      const { type } = action.meta.arg as { type: 'INCLUDE' | 'EXCLUDE' };
      if (type === 'INCLUDE') {
        state.users.include.isLoading = true;
        state.users.include.error = null;
      } else {
        state.users.exclude.isLoading = true;
        state.users.exclude.error = null;
      }
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      const { users, type } = action.payload;
      type === 'INCLUDE'
        ? (state.users.include.values = users)
        : (state.users.exclude.values = users);
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      const { type } = action.payload as { type: 'INCLUDE' | 'EXCLUDE' };
      if (type === 'INCLUDE') {
        state.users.include.values = [];
        state.users.include.error = 'CAN_NOT_FETCH_USERS';
      } else {
        state.users.exclude.values = [];
        state.users.exclude.error = 'CAN_NOT_FETCH_USERS';
      }
    });
    builder.addCase(fetchEventProducts.pending, (state) => {
      state.event.products.isLoading = true;
      state.event.products.error = null;
    });
    builder.addCase(fetchEventProducts.fulfilled, (state, action) => {
      state.event.products.isLoading = false;
      state.event.products.values = action.payload.products;
    });
    builder.addCase(fetchEventProducts.rejected, (state) => {
      state.event.products.isLoading = false;
      state.event.products.values = [];
      state.event.products.error = 'CAN_NOT_FETCH_PRODUCTS';
    });
    builder.addCase(fetchAudiencesMembers.pending, (state) => {
      state.audienceMembers.isLoading = true;
      state.audienceMembers.error = null;
    });
    builder.addCase(fetchAudiencesMembers.fulfilled, (state, action) => {
      state.audienceMembers.isLoading = false;
      state.audienceMembers.count = action.payload.membersCount;
    });
    builder.addCase(fetchAudiencesMembers.rejected, (state) => {
      state.audienceMembers.isLoading = false;
      state.audienceMembers.count = 'N/A';
      state.audienceMembers.error = 'CAN_NOT_FETCH_AUDIENCE_MEMBERS';
    });
  },
});

export default usecasePanel.reducer;
export const {
  open,
  close,
  setUsecase,
  setId,
  toggleStatus,
  setAudienceId,
  setEventProducts,
  setCountry,
  setProduct,
  setUsersToInclude,
  setUsersToExclude,
  updateMarketshare,
  updateSetting,
  clearUsecase,
  setEvent,
  sliceUpdateUsecase,
} = usecasePanel.actions;
