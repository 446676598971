import { t } from 'i18next';

import {
  CALL_INVITED,
  CALL_ATTENDANCE,
  EVENT_FOLLOWUP,
  EVENT_SECOND_FOLLOWUP,
  NAMES_REGEX,
  OPTIONS,
} from '../constants';
import {
  IUsecase,
  IAudiences,
  IPayload,
  Audience,
  TypeTimeline,
  TypeCallTarget,
  ENotificationSentence,
  ENotificationTitle,
} from '@administration/interfaces/IUsecases';
import { IUser } from 'common/interfaces/IUser';

const POST_OPTIONS = OPTIONS.map((option) => option.name);

export const constructPayload = (args: any): IPayload => {
  const {
    currentUsecaseState,
    status,
    country,
    product,
    users,
    marketShare,
    audienceId,
    isCreation,
    event,
  } = args;
  const getCurrentParamater = (title: string) =>
    currentUsecaseState.children[0].settings.find((child: any) => child.title === title);
  const rules = prepareRules(product.id, country.id, users.include.values, users.exclude.values);
  const audiences: IAudiences[] = [
    {
      name: `${country.name} - ${product.name}`,
      status: 'enabled',
      rule: rules,
    },
  ];

  if (!isCreation) {
    audiences[0]['id'] = audienceId;
  }

  const payload = {
    name: `Campaign,${country.name} - ${product.name}`,
    description: `${product.name} campaign for ${country.name}`,
    status: isCreation ? 'enabled' : status,
    tags: [product.name, country.name],
    audiences: audiences,
    flow: {
      name: 'configuration_type_call_pacing',
      status: 'enabled',
      configuration: {
        mccp: {
          name: 'NBA_CONFIGURATION_MCCP_COVERAGE',
          active: getCurrentParamater('NBA_CONFIGURATION_MCCP_COVERAGE').active,
          value: null,
          can_be_modified: false,
        },
        priorities: {
          name: 'NBA_CONFIGURATION_PRIORITIES',
          active: getCurrentParamater('NBA_CONFIGURATION_PRIORITIES').active,
          value: null,
          can_be_modified: false,
        },
        role: {
          name: 'NBA_CONFIGURATION_ROLE',
          can_be_modified: true,
          value: getCurrentParamater('NBA_CONFIGURATION_ROLE').selectedOption,
          active: getCurrentParamater('NBA_CONFIGURATION_ROLE').active,
          values: POST_OPTIONS,
        },
        relative_strength: {
          name: 'NBA_CONFIGURATION_RELATIVE_STRENGTH',
          can_be_modified: true,
          value: getCurrentParamater('NBA_CONFIGURATION_RELATIVE_STRENGTH').selectedOption,
          active: getCurrentParamater('NBA_CONFIGURATION_RELATIVE_STRENGTH').active,
          values: POST_OPTIONS,
        },
        adoption_ladder: {
          name: 'NBA_CONFIGURATION_ADOPTION_LADDER',
          can_be_modified: true,
          value: getCurrentParamater('NBA_CONFIGURATION_ADOPTION_LADDER').selectedOption,
          active: getCurrentParamater('NBA_CONFIGURATION_ADOPTION_LADDER').active,
          values: POST_OPTIONS,
        },
        top_accounts: {
          name: 'NBA_CONFIGURATION_TOP_ACCOUNTS',
          can_be_modified: true,
          value: getCurrentParamater('NBA_CONFIGURATION_TOP_ACCOUNTS').selectedOption,
          active: getCurrentParamater('NBA_CONFIGURATION_TOP_ACCOUNTS').active,
          values: POST_OPTIONS,
        },
        hcp_specialty: {
          name: 'NBA_CONFIGURATION_HCP_SPECIALTY',
          can_be_modified: true,
          value: getCurrentParamater('NBA_CONFIGURATION_HCP_SPECIALTY').selectedOption,
          active: getCurrentParamater('NBA_CONFIGURATION_HCP_SPECIALTY').active,
          values: POST_OPTIONS,
        },
        hcp_type: {
          name: 'NBA_CONFIGURATION_HCP_TYPE',
          can_be_modified: true,
          value: getCurrentParamater('NBA_CONFIGURATION_HCP_TYPE').selectedOption,
          active: getCurrentParamater('NBA_CONFIGURATION_HCP_TYPE').active,
          values: POST_OPTIONS,
        },
        market_share: {
          name: 'NBA_CONFIGURATION_MARKET_SHARE',
          can_be_modified: true,
          value: marketShare.selectedOption,
          active: marketShare.active,
          values: POST_OPTIONS,
          market_share_values: {
            type: marketShare.SettingType,
            activity_thresholds: {
              minimal_activity: {
                value: marketShare.activity_thresholds.minimal_activity.value,
                type: marketShare.activity_thresholds.minimal_activity.type,
              },
              low_alert_level: marketShare.activity_thresholds.low_alert_level,
              high_alert_level: marketShare.activity_thresholds.high_alert_level,
            },
          },
        },
        event: {
          name: 'NBA_CONFIGURATION_MCCP_EVENT',
          can_be_modified: true,
          value: null,
          active: event.active,
          values: POST_OPTIONS,
          configurations: {
            events: {
              types: event.types,
              statuses: event.statuses,
              topics: event.topics,
              product_ids:
                event.products.values && event.products.values.length > 0
                  ? event.products.values.map((product: any) => product.id)
                  : [product.id],
            },
            calls: [
              {
                idx: 0,
                timeline: 'before' as TypeTimeline,
                target: 'attendee' as TypeCallTarget,
                days: event.configurations[CALL_INVITED].days,
                title: 'NBA_EVENT_TITLE_INVITED' as ENotificationTitle.EVENT_INVITED,
                sentence: 'NBA_EVENT_REASON_INVITED' as ENotificationSentence.EVENT_INVITED,
                active: event.configurations[CALL_INVITED].active,
                priorities: event.configurations[CALL_INVITED].priorities,
                attendee_status: event.configurations[CALL_INVITED].attendee_status,
              },
              {
                idx: 1,
                timeline: 'before' as TypeTimeline,
                target: 'attendee' as TypeCallTarget,
                days: event.configurations[CALL_ATTENDANCE].days,
                title: 'NBA_EVENT_TITLE_ACCEPTED' as ENotificationTitle.EVENT_ACCEPTED,
                sentence: 'NBA_EVENT_REASON_ACCEPTED' as ENotificationSentence.EVENT_ACCEPTED,
                active: event.configurations[CALL_ATTENDANCE].active,
                priorities: event.configurations[CALL_ATTENDANCE].priorities,
                attendee_status: event.configurations[CALL_ATTENDANCE].attendee_status,
              },
              {
                idx: 0,
                target: 'attendee' as TypeCallTarget,
                timeline: 'after' as TypeTimeline,
                title: 'NBA_EVENT_TITLE_LATE_FOLLOW_UP' as ENotificationTitle.LATE_FOLLOW_UP,
                sentence: 'NBA_EVENT_REASON_LATE_FOLLOW_UP' as ENotificationSentence.LATE_FOLLOW_UP,
                active: event.configurations[EVENT_FOLLOWUP].active,
                days: event.configurations[EVENT_FOLLOWUP].days,
                priorities: event.configurations[EVENT_FOLLOWUP].priorities,
                attendee_status: event.configurations[EVENT_FOLLOWUP].attendee_status,
              },
              {
                idx: 1,
                target: 'attendee' as TypeCallTarget,
                timeline: 'after' as TypeTimeline,
                title: 'NBA_EVENT_TITLE_LATE_FOLLOW_UP' as ENotificationTitle.LATE_FOLLOW_UP,
                sentence: 'NBA_EVENT_REASON_LATE_FOLLOW_UP' as ENotificationSentence.LATE_FOLLOW_UP,
                active: event.configurations[EVENT_SECOND_FOLLOWUP].active,
                days: event.configurations[EVENT_SECOND_FOLLOWUP].days,
                priorities: event.configurations[EVENT_SECOND_FOLLOWUP].priorities,
                attendee_status: event.configurations[EVENT_SECOND_FOLLOWUP].attendee_status,
              },
            ],
            approved_emails: [],
          },
        },
      },
    },
  };
  return payload;
};

const getMatchResult = (audiences: Audience[], regex: RegExp) => {
  if (audiences.length === 0) {
    return null;
  }
  const matches = audiences[0].name.match(regex);
  return matches && matches.length > 1 ? matches : null;
};

const prepareRules = (
  productId: string,
  countryId: string,
  usersToInclude: IUser[] | null,
  usersToExclude: IUser[] | null,
) => {
  let ruleExpression = `user.product.id==${productId} AND user.country.id==${countryId}`;

  const includedUsersRule =
    usersToInclude && usersToInclude.map((user: IUser) => `user.id == ${user.id}`).join(' OR ');

  const excludedUsersRule =
    usersToExclude && usersToExclude.map((user: IUser) => `user.id != ${user.id}`).join(' AND ');

  if (excludedUsersRule) {
    ruleExpression = `${ruleExpression} AND ${excludedUsersRule}`;
  }

  if (includedUsersRule) {
    ruleExpression = `${ruleExpression} OR ${includedUsersRule}`;
  }

  return ruleExpression;
};

export const reshapeResponse = (response: IUsecase[]) => {
  const reshapedData = response.map((obj: any) => {
    const { id, flow, status, audiences } = obj;
    const journeyName = flow.name.replace('configuration_type_', '').toUpperCase();
    const translatedJourneyName =
      journeyName === 'CALL_PACING' ? t('GLOBAL_CALL_PACING') : t('GLOBAL_EVENT_CONGRES');

    const matches = getMatchResult(audiences, NAMES_REGEX);

    return {
      id,
      product: matches ? matches[2] : 'N/A',
      country: matches ? matches[1] : 'N/A',
      journey: translatedJourneyName,
      running: status,
    };
  });

  return reshapedData;
};
