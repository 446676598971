import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAlertSlice, IPayload } from '../../interfaces/IToast';

const initialState: IAlertSlice = {
  isOpen: false,
  type: 'success',
  title: null,
  message: '',
  keyMessage: '',
  duration: 3000,
  position: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const toastSlice = createSlice({
  name: 'toastSlice',
  initialState,
  reducers: {
    displayToast: (state, action: PayloadAction<IPayload>) => {
      state.isOpen = true;
      state.type = action.payload.type;
      state.title = action.payload.title ? action.payload.title : null;
      state.keyMessage = action.payload.keyMessage ? action.payload.keyMessage : undefined;
      state.message = action.payload.message ? action.payload.message : undefined;
      if (action.payload.position) {
        state.position = { ...action.payload.position };
      }
    },
    hideToast: (state) => {
      state.isOpen = false;
    },
  },
});

export const { displayToast, hideToast } = toastSlice.actions;
export default toastSlice.reducer;
