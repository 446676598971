import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  searchCountryAPI,
  getCountriesWithPagination,
  getActiveCountries,
} from '../../../../../apis/country';
import { IPagination } from '../../../../../interfaces/IPagination';
import { CountryState } from '../../../../../interfaces/ICountry';

export const searchCountry = createAsyncThunk<any, string>(
  'countrySlice/searchCountry',
  async (query: string) => {
    try {
      const response = await searchCountryAPI(query);
      if (response.status === 200) {
        return response.data.values;
      }
      throw new Error();
    } catch (error) {
      return [];
    }
  },
);

export const fetchCountries = createAsyncThunk(
  'countrySlice/fetchCountries',
  async ({ pageSize, offset }: IPagination) => {
    try {
      const response = await getCountriesWithPagination({ pageSize, offset });
      if (response.status === 200) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      return [];
    }
  },
);

export const fetchActiveCountries = createAsyncThunk<any>(
  'countrySlice/fetchActiveCountries',
  async () => {
    try {
      const response = await getActiveCountries();
      if (response.status === 200) {
        return response.data.values;
      }
      throw new Error();
    } catch (error) {
      return [];
    }
  },
);

const initialState: CountryState = {
  countries: [],
  count: 0,
  loading: false,
};

const countrySlice = createSlice({
  name: 'countrySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action: PayloadAction<any>) => {
      state.countries = action.payload.values;
      state.count = action.payload['$count'];
      state.loading = false;
    });
    builder.addCase(fetchCountries.rejected, (state) => {
      state.countries = [];
      state.count = 0;
      state.loading = false;
    });
    // search country state
    builder.addCase(searchCountry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchCountry.fulfilled, (state, action: PayloadAction<any[]>) => {
      state.countries = action.payload;
      state.loading = false;
    });
    builder.addCase(searchCountry.rejected, (state) => {
      state.countries = [];
      state.loading = false;
    });

    // fetch active countries
    builder.addCase(fetchActiveCountries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchActiveCountries.fulfilled, (state, action: PayloadAction<any[]>) => {
      state.countries = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchActiveCountries.rejected, (state) => {
      state.countries = [];
      state.loading = false;
    });
  },
});

export default countrySlice.reducer;
