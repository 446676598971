import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUsecases } from '../../../../../apis/usecases';
import { IPagination } from '../../../../../interfaces/IPagination';

export const fetchUsecases = createAsyncThunk(
  'usecases/fetchUsecases',
  async ({ pageSize, offset }: IPagination) => {
    try {
      const response = await getUsecases({ pageSize, offset });
      if (response.status !== 200) {
        throw new Error('An error occurred while fetching suggestions');
      }
      return response.data;
    } catch (error) {
      throw new Error('An error occurred while fetching suggestions');
    }
  },
);
